import '@atoms/NProgress/NProgress.scss';
import '@styles/_base.scss';

import Favicons from '@atoms/Favicons/Favicons';
import NProgress from '@atoms/NProgress/NProgress';
import LayoutBase from '@layouts/LayoutBase/LayoutBase';
import PreviewMessage from '@molecules/PreviewMessage/PreviewMessage';
import { GoogleTagManager } from '@next/third-parties/google';
import { isCorporateDomain } from '@utils/getDomain';
import { StoreProvider } from '@utils/useStore';
import classNames from 'classnames';
import localFont from 'next/font/local';
import Head from 'next/head';
import Script from 'next/script';
import PropTypes from 'prop-types';

const GoodKarmaFont = localFont({
  src: '../public/fonts/GoodKarmaSmooth-WideUpright.woff2',
  variable: '--font-family-quote',
});

const DINProFont = localFont({
  variable: '--font-family-base',
  src: [
    {
      path: '../public/fonts/DINPro.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/DINPro-Italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../public/fonts/DINPro-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/DINPro-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/DINPro-Black.woff2',
      weight: '900',
      style: 'normal',
    },
  ],
});

function App({ Component, pageProps }) {
  const { preview, page, host } = pageProps;
  const corporateDomain = isCorporateDomain(host);

  const { title, metaTitle, metaDescription, ogImage } = pageProps.page || {};
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    (pageContent => (
      <StoreProvider store={pageProps}>
        <LayoutBase
          title={title}
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          ogImage={ogImage}
        >
          {pageContent}
        </LayoutBase>
      </StoreProvider>
    ));

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />

        {pageProps.customerId && (
          <meta property="prepr:customer-id" content={pageProps.customerId} />
        )}
        {page?.id && <meta property="prepr:id" content={page.id} />}

        <Favicons />
      </Head>

      {page?.id && (
        <Script
          id="prepr-connection"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              ! function (e, t, p, r, n, a, s) {
              e[r] || ((n = e[r] = function () {
              n.process ? n.process.apply(n, arguments) : n.queue.push(arguments)
              }).queue = [], n.t = +new Date, (a = t.createElement(p)).async = 1, a.src = "https://cdn.tracking.prepr.io/js/prepr_v2.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (s = t.getElementsByTagName(p)[0]).parentNode.insertBefore(a, s))
              }(window, document, "script", "prepr"), prepr("init", "${
                corporateDomain
                  ? process.env.PREPR_CORPORATE_TRACKING_TOKEN
                  : process.env.PREPR_JOBS_TRACKING_TOKEN
              }"), prepr("event", "pageload");
              `,
          }}
        />
      )}

      <GoogleTagManager
        gtmId={
          corporateDomain
            ? process.env.CORPORATE_GTM_CODE
            : process.env.JOBS_GTM_CODE
        }
      />

      <NProgress />
      <PreviewMessage preview={preview} />
      <div
        className={classNames(
          'nextFonts',
          GoodKarmaFont.variable,
          DINProFont.variable
        )}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {getLayout(<Component {...pageProps} />)}
      </div>
    </>
  );
}

App.propTypes = {
  Component: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object,
};

export default App;
