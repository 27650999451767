import PropTypes from 'prop-types';

import ctaType from './ctaType';
import storyItemType from './storyItemType';

const relatedStoriesType = {
  title: PropTypes.string,
  text: PropTypes.string,
  relatedStories: PropTypes.shape({
    sameFieldsOfWorkStories: PropTypes.shape({
      items: PropTypes.arrayOf(storyItemType),
    }),
    upcomingStories: PropTypes.shape({
      items: PropTypes.arrayOf(storyItemType),
    }),
  }).isRequired,
  cta: ctaType,
};

export default relatedStoriesType;
