import ContactLink from '@atoms/ContactLink/ContactLink';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import RichText from '@atoms/RichText/RichText';
import FacebookIcon from '@svg/facebook.svg';
import InstagramIcon from '@svg/instagram.svg';
import LinkedInIcon from '@svg/linkedin.svg';
import VitalisLogo from '@svg/vitalis-logo.svg';
import YoutubeIcon from '@svg/youtube.svg';
import useStore from '@utils/useStore';
import classNames from 'classnames';
import Link from 'next/link';

import styles from './Footer.module.scss';

const socials = [
  {
    Icon: InstagramIcon,
    title: 'Instagram',
    href: 'https://www.instagram.com/vitalisgroep/',
  },
  {
    Icon: LinkedInIcon,
    title: 'LinkedIn',
    href: 'https://nl.linkedin.com/company/vitalisgroep',
  },
  {
    Icon: YoutubeIcon,
    title: 'Youtube',
    href: 'https://www.youtube.com/user/vitaliswoonzorggroep',
  },
  {
    Icon: FacebookIcon,
    title: 'Facebook',
    href: 'https://www.facebook.com/VitalisGroep',
  },
];

function Footer() {
  const {
    store: {
      page: { typename = 'Content' } = {},
      config: { footer },
      isCorporateDomain,
    },
  } = useStore();

  if (!footer) return null;

  const {
    navigationTitle,
    navigationMenu,
    contactTitle,
    contactLinks,
    openingTimes,
    seniorTitle,
    seniorText,
    seniorLinks,
    socialsTitle,
    bottomLinks,
  } = footer;

  return (
    <footer className={classNames(styles.footer, styles[typename])}>
      <div className={styles.wrapper}>
        <div className={classNames('u-container', styles.container)}>
          <div className={styles.group}>
            <h2 className={styles.title}>{navigationTitle}</h2>
            <ul className={classNames('u-list-clean', styles.list)}>
              {navigationMenu.map(menuItem => (
                <li key={menuItem.id}>
                  <NavigationItem item={menuItem} extraClasses={styles.link} />
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.group}>
            <h2 className={styles.title}>{contactTitle}</h2>
            {openingTimes && (
              <RichText text={openingTimes} className={styles.text} />
            )}
            <ul className={classNames('u-list-clean', styles.list)}>
              {contactLinks.map(link => (
                <li key={link.id}>
                  <ContactLink link={link} />
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.group}>
            <h2 className={styles.title}>{seniorTitle}</h2>
            {seniorText && (
              <RichText text={seniorText} className={styles.text} />
            )}
            <ul className={classNames('u-list-clean', styles.list)}>
              {seniorLinks.map(link => (
                <li key={link.id}>
                  <ContactLink link={link} />
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.group}>
            <h2 className={styles.title}>{socialsTitle}</h2>
            <ul className={classNames('u-list-clean', styles.socials)}>
              {socials.map(({ title, href, Icon }) => (
                <li key={title}>
                  <a
                    href={href}
                    target="_blank"
                    title={title}
                    aria-label={title}
                    className={styles.social}
                  >
                    <Icon className={styles.socialIcon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.bottom}>
            <ul className={classNames('u-list-clean', styles.bottomList)}>
              <li>&copy; Vitalis</li>

              {bottomLinks.map(menuItem => (
                <li key={menuItem.id}>
                  <NavigationItem
                    item={menuItem}
                    extraClasses={classNames(styles.link, styles.thin)}
                  />
                </li>
              ))}
            </ul>

            <Link href="/" className={styles.logo} aria-label="Home">
              <VitalisLogo />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
