import fetchApi from '@lib/fetchApi';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';

import setUrlSearchParams from './setUrlSearchParams';
import useDidMountEffect from './useDidMountEffect';
import useForm from './useForm';
import useSortedFilters from './useSortedFilters';

const emailPreferences = [
  {
    id: '1',
    name: 'emailPreference',
    label: 'Zodra de vacature zichtbaar is',
    value: 'DIRECT',
  },
  {
    id: '2',
    name: 'emailPreference',
    label: 'Elke week',
    value: 'WEEKLY',
  },
  {
    id: '3',
    name: 'emailPreference',
    label: 'Elke maand',
    value: 'MONTHLY',
  },
];

export default function useJobAlert({ filters: initFilters, onSuccess }) {
  const { query, ...router } = useRouter();
  const [fetchTimeout, setFetchTimeout] = useState(useRef());
  // useMemo so we dont update the useForm inputs each time we update the query params
  const inputsFromQuery = useMemo(
    () => ({
      applicationForms: query.applicationForms
        ? query.applicationForms?.split(',')
        : [],
      functionCategories: query.functionCategories
        ? query.functionCategories?.split(',')
        : [],
      locations: query.locations ? query.locations?.split(',') : [],
      educationLevels: query.educationLevels
        ? query.educationLevels?.split(',')
        : [],
      subjectAreas: query.subjectAreas ? query.subjectAreas?.split(',') : [],
      minHours: query.minHours,
      maxHours: query.maxHours,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { inputs: filterInputs, handleChange: handleFilterChange } = useForm({
    ...inputsFromQuery,
  });
  const { inputs, handleChange } = useForm({
    name: '',
    email: '',
    emailPreference: emailPreferences[0].value,
  });
  const [formState, setFormState] = useState('active'); // Comment: active, loading, success, error

  const filters = [
    {
      title: 'Hoe wil je bij ons werken?',
      type: 'checkbox',
      name: 'applicationForms',
      options:
        useSortedFilters({
          applicationForms: initFilters?.applicationForms,
        })?.applicationForms?.map(func => ({
          ...func,
          label: func.title,
          checked: filterInputs.applicationForms.includes(`${func.id}`),
        })) || [],
      onChange: handleFilterChange,
    },
    {
      title: 'Werkveld',
      type: 'checkbox',
      name: 'functionCategories',
      options:
        useSortedFilters({
          functionCategories: initFilters?.functionCategories,
        })?.functionCategories?.map(func => ({
          ...func,
          label: func.title,
          checked: filterInputs.functionCategories.includes(`${func.id}`),
        })) || [],
      onChange: handleFilterChange,
    },
    {
      title: 'Doelgroep',
      type: 'checkbox',
      name: 'subjectAreas',
      options:
        useSortedFilters({
          subjectAreas: initFilters?.subjectAreas,
        })?.subjectAreas?.map(edu => ({
          ...edu,
          label: edu.title,
          checked: filterInputs.subjectAreas.includes(`${edu.id}`),
        })) || [],
      onChange: handleFilterChange,
    },
    {
      title: 'Opleidingsniveau',
      type: 'checkbox',
      name: 'educationLevels',
      options:
        initFilters?.educationLevels?.map(edu => ({
          ...edu,
          label: edu.title,
          checked: filterInputs.educationLevels.includes(`${edu.id}`),
        })) || [],
      onChange: handleFilterChange,
    },
    {
      title: 'Locaties',
      type: 'checkbox',
      name: 'locations',
      options:
        initFilters?.locations?.map(loc => ({
          ...loc,
          label: loc.title,
          checked: filterInputs.locations.includes(`${loc.id}`),
        })) || [],
      onChange: handleFilterChange,
    },
    {
      title: 'Aantal uren',
      type: 'numberRange',
      names: ['minHours', 'maxHours'],
      values: [filterInputs.minHours, filterInputs.maxHours],
      onChange: handleFilterChange,
    },
  ];

  useDidMountEffect(() => {
    setFetchTimeout(clearTimeout(fetchTimeout));

    setFetchTimeout(
      setTimeout(() => {
        setUrlSearchParams({
          params: { ...query, ...filterInputs },
          router,
        });
      }, 200)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputs]);

  const handleSubmit = async e => {
    e.preventDefault();
    setFormState('loading');

    const variables = {
      ...inputs,
      ...filterInputs,
      minHoursPerWeek: filterInputs.minHours,
      maxHoursPerWeek: filterInputs.maxHours,
    };
    delete variables.minHours;
    delete variables.maxHours;

    await fetchApi({
      method: 'post',
      path: '/job_alerts/create',
      variables,
    })
      .then(() => {
        setFormState('success');
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(error => {
        console.error(error);
        setFormState('error');
      });
  };

  return {
    filters,
    inputs,
    handleChange,
    emailPreferences,
    handleSubmit,
    formState,
  };
}
