import { assetFragment, eventItemFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const EventsSectionFragment = gql`
  fragment eventsSectionFields on EventsSection {
    typename: __typename
    title
    highlightedOnly: show_only_highlighted_events
    events {
      ...eventItemFields
    }
    locations: events_location {
      id: _id
    }
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
  ${eventItemFragment}
`;

export default EventsSectionFragment;
